html {
  background-color: #dec4932b;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-link {
  color: #61dafb;
}

.social-link {
  padding-left: 2px;
  padding-right: 2px;
  max-width: 30px;
}
.social-link:hover {
  opacity: 0.5;
  filter: alpha(opacity=50); /* For IE8 and earlier */
}

.ta-r {
  text-align: right;
}
.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mtb-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mtb-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.logo-footer {
  width: 240px;
}

.footer-text {
  font-size: 16px;
}

.latest-app {
  margin-top: 100px;
}

.text-container {
  margin-left: 40px;
  margin-right: 40px;
  color: black;
  font-weight: 200;
}

.latest-app-img {
  /* max-width: 400px; */
}

h1 {
  font-weight: 700;
  font-size: 25px;
}

h2 {
  font-weight: 100;
  margin-top: 20px;
}

ul {
  list-style: circle;
}

li {
  font-weight: 200;
  text-align: left;
  margin-top: 5px;
  font-size: 14px;
}

.thin {
  font-weight: 200;
}

.full-width {
  padding-left: 0px;
  padding-right: 0px;
}

.app-img {
  max-width: 400px;
  height: 400px;
  /* margin-left: 30px; */
  width: 100%;
  border-radius: 20px;
  margin-top: 30px;
  text-align: center;
  margin: 0 auto;
}

.featured-app {
  background-color: #d0cec970;
  padding-top: 40px;
  padding-bottom: 80px;
}

.app-featured-title {
  color: white;
  font-size: 30px;
  font-weight: 300;
  padding-top: 25px;
}

.app-featured-button {
  margin-top: 255px;
}

.img-header {
  height: 400px;
}

.app-detail-name {
  background-color: #1f1c1c;
  color: white;
  font-weight: 300;
}

.app-download-text {
  font-weight: 200;
  text-transform: uppercase;
  font-size: 22px;
}

.app-download-img {
  width: auto;
  height: 45px;
  display: inherit;
  margin: 10px;
}

.player-wrapper {
  height: 400px;
}

.instagram-feed {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.instagram-img {
  padding: 20px;
  border-radius: 120px;
}

.instagram-logo {
  height: 50px;
  width: auto;
}

.instagram-header {
  margin-top: 40px;
  height: 50px;
}

.instagram-text {
  line-height: 50px;
  top: -19px;
  /* margin-top: -50px; */
  padding-top: -50px;
  position: relative;
  height: 50px;
  font-weight: 300;
  font-size: 21px;
  padding-left: 10px;
}

.app-video {
  max-width: 600px;
  margin: auto;
}

.app-video-header {
  max-width: 800px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 60px;
}

.img-manual {
  width: 80px;
  height: auto;
}

@media screen and (max-width: 768px) {
  html,
  body {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }

  .scrollable-navbar {
    height: 500px;
    max-height: 500px;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .app-img {
    max-width: 400px;
    max-height: 400px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    border-radius: 20px;
    text-align: center;
    margin: 0 auto;
  }

  .img-header {
    height: 280px;
  }

  .instagram-feed {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  .instagram-img {
    padding: 10px;
  }
}

.topbar {
  height: 60px;
  background-color: black;
}

.newsletter button {
  float: left;
}

.newsletter h1 {
  text-align: right;
  background: transparent;
  padding-top: unset;
}

.newsletter {
  padding-top: 37px;
  background: #3a3030;
  height: 115px;
  margin-left: -160px;
}

@media screen and (max-width: 768px) {
  .latest-app {
    display: flex;
    flex-direction: column;
  }

  .order-1 {
    order: 1;
  }

  .order-2 {
    order: 2;
  }

  .newsletter h1 {
    text-align: center;
    height: auto;
  }

  .newsletter .field {
    width: 90%;
    margin: auto;
  }

  .newsletter {
    padding-top: 54px;
    background: #3a3030;
    height: 280px;
    margin-left: unset;
  }

  .newsletter button {
    float: unset;
  }
}

@media screen and (min-width: 768px) {
  .navbar-dropdown {
    right: 0;
    left: auto;
  }

  .user-manual {
    max-width: 800px;
    margin: auto;
    margin-top: 40px;
  }
  .app-detail-name {
    background-color: #1f1c1c;
    color: white;
    font-weight: 300;
    height: 60px;
    font-size: 30px;
    padding-top: 6px;
  }

  .latest-app-img {
    max-width: 400px;
    border-radius: 20px;
  }

  .latest-app {
    max-width: 1100px;
    margin: auto;
    margin-top: 100px;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
