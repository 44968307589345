@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* .slick-slider {
  position: relative;
  width: 100%;
  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
} */

.slick-slide img {
  display: inherit;
}

.carousel-title {
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 20px;
}

.carousel-subtitle {
  color: white;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 18px;
  margin-top: 0px;
}

.carousel {
  height: 550px;
  /* padding-top: 45%; */
  /* max-width: 100%; */
  background-size: cover;
  background-position: center center;
}

.carousel-button {
  background: #e7a800;
  padding: 0px 30px;
  border-radius: 3px;
  border: 2px solid #f3b100 !important;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  width: 150px;
  height: 40px;
}

.button[disabled] {
  background: #decc9c;
  border: 2px solid #decc9c !important;
  color: #6d4343;
}
.carousel-store-img {
  width: auto;
  height: 43px;
  margin-top: -1px;
  padding-left: 20px;
  display: inherit;
}

.carousel-app {
  height: 200px;
  width: 200px;
  margin-left: 50px;
  border-radius: 10px;
}

@media screen and (max-width: 769px) {
  .carousel {
    background-position-x: -340px;
  }

  .carousel-button-group {
    margin-top: 320px;
  }
}

@media screen and (min-width: 769px) {
  .carousel-title {
    padding-top: 0px;
  }

  .carousel-subtitle {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .left-column {
    max-width: 400px;
    margin-left: 30px;
    margin-top: 150px;
  }
}
