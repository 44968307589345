.navbar {
  background-color: rgba(29, 24, 24, 1);
  min-height: 3.25rem;
  position: relative;
  z-index: 99;
  opacity: 1 !important;
  top: 0;
  position: fixed;
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.navbar-transparent {
  background-color: rgba(255, 255, 255, 0);
}

.navbar-item,
.navbar-link {
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  /* font-family: Montserrat; */
  font-weight: 700;
}

.navbar-item-title {
}

.mavbar-divider {
  height: 1px;
}
.navbar-item-listelement {
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: white;
}

.navbar-dropdown {
  background-color: #020000e0;
}

.navbar-item img {
  max-height: 40px;
  padding: 5px;
}
.navbar-burger span {
  height: 2px;
  color: white;
}

@media screen and (max-width: 768px) {
  .navbar-menu {
    background-color: #020000e0;
  }
  .navbar {
    height: 40px;
  }
  .navbar-item img {
    max-height: 20px;
    padding: 2px;
    margin-top: 10px;
  }
}
